import { collection, onSnapshot, query } from "firebase/firestore";
import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import { AuthContext } from "../context/AuthContext";
import { firestore } from "../firebase/config";
import { replaceImage } from "../utility";

const Users = () => {
  const { user } = useContext(AuthContext);
  const [allUsers, setAllUsers] = useState();
  useEffect(() => {
    const allUsers = async () => {
      const q = query(collection(firestore, "user"));
      onSnapshot(q, (snapshot) => {
        let users = snapshot.docs?.map((doc) => ({
          ...doc.data(),
          id: doc?.id,
        }));
        console.log(users);
        users = users.sort(
          (a, b) => (b.posts?.length || 0) - (a.posts?.length || 0)
        );
        setAllUsers(users);
      });
    };
    return allUsers();
  }, []);
  return (
    <div>
      <Header />

      <div className="p-3 lg:max-w-4xl min-h-screen mt-14 md:p-3 lg:mx-auto mb-8">
        <table className="table-auto w-full">
          <tbody>
            {allUsers?.map((item, index) => (
              <tr key={index}>
                <td>
                  <div className="flex items-center justify-between my-2">
                    <div className="flex gap-2 items-center">
                      <Link to={`/${item?.username}`}>
                        <img
                          src={replaceImage(item?.photoURL, 50)}
                          className="h-7 w-7 aspect-square object-cover rounded-full"
                          alt={item?.username}
                        />
                      </Link>
                      <div>
                        <Link
                          to={`/${item?.username}`}
                          className="text-sm font-semibold text-gray-800"
                        >
                          {item?.username}
                        </Link>
                        <p className="text-[10px] text-gray-500">
                          {item.fullName}
                        </p>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {item?.posts?.length || 0}
                  <img src="/images/logo.png" className="w-8 inline-block	" />
                </td>
                <td>
                  {user?.uid !== item?.id && (
                    <Link
                      to={`/${item?.username}`}
                      className={`${
                        item?.followedBy?.includes(user?.uid)
                          ? "text-gray-400"
                          : "text-blue-500"
                      } text-xs font-bold `}
                    >
                      {item?.followedBy?.includes(user?.uid)
                        ? "Followed"
                        : "Follow"}
                    </Link>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </div>
  );
};
export default Users;
