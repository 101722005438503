import { initializeApp } from "firebase/app";

import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyCYy5UowBrhPjMf0wM1XLyi1XlfN8JRJrw",
  authDomain: "nilyfans-e5948.firebaseapp.com",
  projectId: "nilyfans-e5948",
  storageBucket: "nilyfans-e5948.appspot.com",
  messagingSenderId: "806668647770",
  appId: "1:806668647770:web:6b4ee7d5e3763926db0763",
  measurementId: "G-SX5ECGE9KX",
};

const app = initializeApp(firebaseConfig);
const firestore = getFirestore(app);
const auth = getAuth(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
export { firestore, auth, storage, analytics };
