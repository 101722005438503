// export arrow function which tells valid email address
export const isValidEmail = (email) => {
  const test =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  return test.test(email);
};

export const nFormatter = (num) => {
  if (num < 1e3) return num.toLocaleString();
  else if (num >= 1e6) return `${(num / 1e6).toString().slice(0, 4)}m`;
  else if (num >= 1e3) return `${(num / 1e3).toString().slice(0, 3)}k`;
};

export const replaceImage = (url, size) => {
  if (!url || !url.startsWith("https://firebasestorage.googleapis.com/v0/b/"))
    return url;
  // available sizes {50, 200, 800, 1500} to be configurable in firebase
  const urlParts = url.split("/");

  // if the filename has no extension then split by "?" from the URL
  const fileName = urlParts.at(-1);
  let splitChar = ".";
  if (!fileName.includes(".")) splitChar = "?";

  const fileParts = fileName.replaceAll("%2F", "/").split(splitChar);
  const postFix = `_${size}x${size}${splitChar}`;
  const newFile = [
    ...fileParts.slice(0, 1),
    postFix,
    ...fileParts.slice(1),
  ].join("");
  const newUrl =
    urlParts.slice(0, -1).join("/") + "/" + newFile.replaceAll("/", "%2F");
  return newUrl;
};
